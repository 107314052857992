.quiz{
    background-color: rgb(42, 42, 42);
    /* display: flex; 
    min-height: 100vh;
    justify-content: center; 
    flex-direction: column; 
    text-align: center; */
}
/* .image{
    max-width: 18vw;
    height: auto;
    display: block;
    margin: 0 auto;
} */
.unordered{
    list-style-type: none;
    padding: 0;
}
.buttons{
    display: block;
}
.popup{
    border: groove #e21833; 
    /* padding: 1rem 2rem 1rem 2rem;
    font-size: large; */
    background-color: #ffd200;
}
.submit-button,
.next-button,
.option-button{
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    /* font-size: 1.5rem;
    padding: 0.5rem 1rem;
    margin: 2px; */
    border: solid rgba(255, 255, 255, 0.6);
    /* border-radius: 5px; */
    /* transition: all 0.3s ease; */
}
  
.submit-button:hover {
    background-color: white;
    color: black;
}
.next-button:hover{
    background-color: white;
    color: black;
}
.Question-Number{            
   font-size: 3rem;
}
h3{
    font-size: 1rem;
}

.hiddenscroll::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenscroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
