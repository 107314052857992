.filter-overlay {
  background-color: rgba(0, 0, 0, 0.215);
  border-radius: 25px;
  padding: 25px;
}

.filter-typing {
  font-size: 1.2em;
  color: rgb(220, 220, 220);
  background-color: rgba(0, 0, 0, 0.215);
  border-radius: 25px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
}

.filter-dropdown-background {
  border-radius: 25px;
  background-color: rgb(56, 56, 56);
  padding: 10px;
}
