.confirmation{
    background-color: rgb(42, 42, 42);
    height: 100%;
    display: flex; 
    justify-content: center; 
    flex-direction: column; 
    text-align: center;
}
.start-button{
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    margin: 2px;
    border: 2px solid rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    transition: all 0.3s ease;
}
.start-button:hover{
    background-color: white;
    color: black;
}
h1{
    font-size: 3rem;
}
h3{
    font-size: 1rem;
}
